/* eslint no-irregular-whitespace: 0 */

const SunsetFaq = [
  {
    question: '1. Why is glo being discontinued in Canada?',
    response: `<p>We are optimizing our brand portfolio. We strongly believe Vype, our vaping brand, is the optimal choice for adult consumers seeking an alternative to traditional cigarettes.</p>`
  },
  {
    question: '2. Will neo sticks still be available?',
    response: `<p>Both neo sticks and glo will be discontinued.</p>`
  },
  {
    question: '3. Until when can I buy glo and/or neo sticks in-store?',
    response: `<p>ITCO will no longer be shipping product to stores after September 4th, 2019. There may be remaining inventory available in certain stores after that date, depending on the retailer. The last day to buy products on glo.ca is September 30th, 2019.</p>`
  },
  {
    question: '4. Is there anywhere I can still buy glo and/or neo sticks after September?',
    response: `<p>Both glo and neo sticks will be available for purchase on glo.ca until September 30th, 2019. You can check whether your usual retailer still has neo sticks on shelves, although we will not be shipping to stores after September 4th, 2019.</p>`
  },
  {
    question: '5. What are my options now?',
    response: `<p>For adult consumers looking for an alternative to smoking, Vype offers a range of high-quality vaping devices, e-liquid bottles, and cartridges. Learn more at <a href="https://govype.ca/">govype.ca</a>.</p>`
  }
]

export default SunsetFaq
