import React from 'react'
import SunsetFaq from '../utils/sunset-faq'

import Layout from 'components/Layout'
import Section, { SectionBlock } from 'components/Section'
import Wrapper from 'components/Wrapper'
import RichText from 'components/RichText'
import Question from 'components/Question'
import { H2 } from 'styles/typography'

export default () => {
  return (
    <Layout>
      <Section>
        <Wrapper narrow>
          <SectionBlock className='u-text-center'>
            <H2>glo is being discontinued</H2>
          </SectionBlock>
          <SectionBlock>
            {SunsetFaq.map(question => (
              <Question question={question.question}>
                <RichText>
                  <p dangerouslySetInnerHTML={{ __html: question.response }} />
                </RichText>
              </Question>
            ))}
          </SectionBlock>
        </Wrapper>
      </Section>
    </Layout>
  )
}
