import React, { useState } from 'react'
import { node, string, func } from 'prop-types'
import styled from 'styled-components'
import { stripUnit } from 'polished'

import { primaryColor, greyDark, borderColor } from 'styles/colors'
import { animationTime, animationCurve } from 'styles/global'
import { H5, lineHeights } from 'styles/typography'
import * as spacing from 'styles/spacing'
import { media } from 'styles/media'
import Icon from 'components/Icon'

const Container = styled.div`
  position: relative;

  & + & {
    border-top: 1px solid ${borderColor};
    margin-top: ${stripUnit(spacing.medium) * 1.25 + 'px'};
    padding-top: ${stripUnit(spacing.medium) * 1.25 + 'px'};

    ${media.large`
      margin-top: ${spacing.large};
      padding-top: ${spacing.large};
    `};
  }
`

const Trigger = styled.button`
  display: flex;
  width: 100%;
  text-align: left;
  color: ${greyDark};

  &:hover {
    color: ${primaryColor};
  }
`

const Title = styled(H5)`
  flex-grow: 1;
  padding-right: ${spacing.medium};
  margin-bottom: 0;
  color: inherit;
  line-height: ${lineHeights.base};
  transition: color ${animationTime} ${animationCurve};

  ${media.medium`
    padding-right: ${spacing.large};
  `};
`

const IconWrap = styled.div`
  flex-shrink: 0;
  transition: color ${animationTime} ${animationCurve};
  transform: ${props => (props.expanded ? 'rotate(180deg)' : 'none')};
`

const Answer = styled.div`
  margin-top: ${stripUnit(spacing.small) * 1.5 + 'px'};
`

const Question = ({ question, children }) => {
  const [expanded, setExpanded] = useState(false)
  return (
    <Container>
      <Trigger onClick={() => { setExpanded(!expanded) }}>
        <Title>{question}</Title>
        <IconWrap expanded={expanded}>
          <Icon icon="down-chevron" />
        </IconWrap>
      </Trigger>
      {expanded ? <Answer>{children}</Answer> : null}
    </Container>
  )
}

Question.propTypes = {
  children: node.isRequired,
  question: string.isRequired
}

export default Question
