import React from 'react'
import { node } from 'prop-types'
import styled from 'styled-components'

import * as spacing from 'styles/spacing'
import { greyDark } from 'styles/colors'
import { media } from 'styles/media'

const Container = styled.div`
  position: static;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: ${greyDark};
  }

  ul,
  ol {
    padding-left: ${spacing.large};
    margin-bottom: 1.25em;

    &:last-child {
      margin-bottom: 0;
    }

    ${media.medium`
      margin-bottom: 1.5em;
    `};

    li + li {
      margin-top: ${spacing.small};
    }
  }

  ul {
    list-style: none;

    li {
      position: static;

      &:before {
        content: '•';
        display: inline-block;
        width: ${spacing.medium};
        margin-left: -${spacing.medium};
      }
    }
  }

  ol {
    list-style: none;
    counter-reset: numbers;

    li {
      position: static;

      &:before {
        counter-increment: numbers;
        content: counter(numbers) ". ";
        display: inline-block;
        width: ${spacing.large};
        margin-left: -${spacing.large};
      }
    }
  }
`

const RichText = ({ children }) => <Container>{children}</Container>

RichText.propTypes = {
  children: node.isRequired
}

export default RichText
